<template>
  <div id="app-container" :class="appClass">
    <the-header></the-header>
    <router-view/>
    <base-modal v-if="showLogin"></base-modal>
    <snack-bar v-if="updateExist && showUpdate" v-on:close="hideUpdate">
      Update available <span id="update-btn" @click="updateApplication">Update</span>
    </snack-bar>
  </div>
</template>

<script>
import TheHeader from "@/components/layout/TheHeader";
import BaseModal from "@/components/ui/BaseModal";
import SnackBar from "@/components/ui/SnackBar";
import Update from "@/mixins/update"

export default {
  components: {SnackBar, BaseModal, TheHeader},

  mixins: [Update],

  computed: {
    appClass() {
      return {
        dark: this.$store.getters.darkMode,
      }
    }
  },

  data() {
    return {
      updateReminderPeriodMS: 1000 * 60 * 10,
      showUpdate: true,
      showLogin: false,
    }
  },

  methods: {
    hideUpdate() {
      this.showUpdate = false;
      setTimeout(() => {
        if (this.updateExist) {
          this.showUpdate = true;
        }
      }, this.updateReminderPeriodMS);

      this.updateReminderPeriodMS *= 2;
    }
  },

  created() {
    this.$store.dispatch('loadGlobalState');
  }
}
</script>

<style>
* {
  font-family: "JetBrains Mono";
  box-sizing: border-box;
  --rounded-border-radius: 9px;
}

#app-container {
  max-width: 600px;
  margin: 0 auto;
}

@font-face {
  font-family: "JetBrains Mono";
  src: local("JetBrains Mono"),
    url("./fonts/JetBrains_Mono/JetBrainsMono-VariableFont_wght.ttf") format('truetype');
}

.dark {
  --main-bg-color: #212121;
  --card-bg-color: #263238;
  --input-bg-color: #263238;
  --dropdown-bg-color: lightgray;
  --dropdown-text-color: #212121;
  --dropdown-hl-color: darkgray;
  /*--card-bg-color: #183940;*/
  /*--input-bg-color: #183940;*/
  --main-text-color: lightgray;
  --active-selection-color: #212121;

  --push-btn-bg-color: #212121;
  --push-btn-border-color: #3c3c3c;
  --push-btn-text-color: lightgray;
  --push-btn-shadow-color: #3c3c3c;

  --btn-border-color: lightgray;
  --btn-soft-border-color: #212121;
  --btn-soft-text-color: #212121;

  --tab-border-color: darkgray;

  --list-card-border-color: #212121;
  --list-card-shadow-color: #212121;

  background-color: var(--main-bg-color);
  color: var(--main-text-color);
}

.light {
  --main-bg-color: white;
  --card-bg-color: white;
  --input-bg-color: lightgray;
  --dropdown-bg-color: white;
  --dropdown-text-color: #252730;
  --dropdown-hl-color: lightgray;
  --main-text-color: #252730;

  --active-selection-color: lightgray;

  --push-btn-bg-color: lightgray;
  --push-btn-border-color: darkgray;
  --push-btn-text-color: #252730;
  --push-btn-shadow-color: darkgray;

  --btn-soft-border-color: lightgray;
  --btn-soft-text-color: lightgray;

  --tab-border-color: #252730;

  --list-card-border-color: lightgray;
  --list-card-shadow-color: darkgray;

  background-color: var(--main-bg-color);
  color: var(--main-text-color);
}

#update-btn {
  background-color: var(--main-text-color);
  border: var(--main-text-color) solid;
  border-radius: 3px;
  color: var(--main-bg-color);
  padding: 7px;
  margin: 11px;
}
</style>

