<template>
  <base-card>
    <timer
        v-on:period-completed="completeTimerPeriod"
    ></timer>
  </base-card>
  <base-card id="focus-card">
    <h3>Focus</h3>
    <span id="project-tag" v-if="focusProject !== ''">{{ focusProject }} </span>
    <span v-if="focusTask !== ''">{{ focusTask }}</span>
  </base-card>
  <base-card>
    <task-list v-on:focusChanged="updateFocus"></task-list>
  </base-card>
</template>

<script>
// @ is an alias to /src
import firebase from "firebase/app";
import 'firebase/firestore';
import Timer from "@/components/Timer";
import BaseCard from "@/components/ui/BaseCard";
import TaskList from "@/components/TaskList";

export default {
  task: 'Home',

  components: {
    TaskList,
    BaseCard,
    Timer
  },

  provide() {
    return {
      focusMinutes: this.focusMinutes,
      shortBreakMinutes: this.shortBreakMinutes,
      longBreakMinutes: this.longBreakMinutes,
    };
  },

  data() {
    return {
      focusMinutes: 25,
      shortBreakMinutes: 5,
      longBreakMinutes: 15,
      focusTask: '',
      focusProject: '',
      chime: null,
    }
  },

  mounted() {
    this.chime = new Audio(require('../assets/period-complete-chime.mp3'));
  },

  methods: {
    completeTimerPeriod(payload) {
      if (this.$store.getters.playChime && this.chime) {
        this.chime.play();
      }

      if (payload.mode !== 'focus') {
        return;
      }

      const user = this.$store.getters.user;
      if (!user) {
        return;
      }

      const db = firebase.firestore();
      db.collection('users')
          .doc(user.id)
          .collection('completedPeriods')
          .add({
            project: this.focusProject ? this.focusProject : 'None',
            task: this.focusTask ? this.focusTask: 'None',
            timeStamp: Date.now(),
            minutes: payload.minutes,
          })
          .catch((error) => {
            console.log(error);
          });
    },

    updateFocus(payload) {
      this.focusTask = payload.task;
      this.focusProject = payload.project;
    }
  }
}
</script>

<style scoped>
#project-tag {
  color: gray;
  font-weight: bold;
}

#focus-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-wrap: anywhere;
}
</style>