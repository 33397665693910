<template>
  <button :class="classObject">
    <slot></slot>
  </button>
</template>

<script>
export default {
  task: "BaseButton",

  props: ['size'],

  computed: {
    classObject() {
      return {
        'btn-black': true,
        'btn': true,
        'btn-tny': this.size === 'tiny',
        'btn-sm': this.size === 'small',
        'btn-md': this.size === 'medium',
        'btn-lg': this.size === 'large',
      };
    }
  }
}

</script>

<style scoped>
button {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn {
  display: inline-block;
  margin: 6px;
  font-size: inherit;
  line-height: 1.42;
  padding: 0.7em 1.4em;
  font-weight: normal;
  border-width: 3px;
  border-style: solid;
  background: transparent;
  border-radius: 0;
  cursor: pointer;
  font-family: "Booster Next FY", "Avenir Next", Avenir, sans-serif;
  user-select: none;
  vertical-align: bottom;
  position: relative;
  transform: translate(0.5em -0.4em);
  transition: transform 0.2s;
}

.btn.btn-disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.btn.btn-disabled:active {
  opacity: 0.6;
}

.btn:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  transform: translate(0.6em, -0.3em);
  transition: transform 0.2s, background-color 0.2s;
}

.btn-black {
  color: var(--main-text-color);
  border-color: var(--button-border-color);
}

.btn-black:hover {
  transform: translate(0.2em, -0.1em);
}

.btn-black:hover:before {
  transform: translate(-0.4em, 0.4em);
}

.btn-black:active {
  transform: translate(0, 0);
  transition-duration: 0.1s;
}

.btn-black:active:before {
  transition-duration: 0.1s;
  transform: translate(0, 0);
  background-color: rgba(46, 37, 48, 0.5);
}

.btn-black:before {
  background-color: rgba(46, 37, 48, 0.4);
}

.btn-tny {
  font-size: 0.55em !important;
}

.btn-sm {
  font-size: 0.85em !important;
}

.btn-md {
  font-size: 1.2em !important;
}

.btn-lg {
  font-size: 1.6em !important;
}
</style>