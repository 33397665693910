import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
// import NoSleep from 'nosleep.js'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BaseButton from "@/components/ui/BaseButton";
import BaseDropdown from "@/components/ui/BaseDropdown";
import './registerServiceWorker'

const app = createApp(App)

app.component('base-button', BaseButton);
app.component('base-dropdown', BaseDropdown);

app.use(store);
app.use(router);

const firebaseConfig = {
    apiKey: "AIzaSyCN_JMiUL2pZA_bj8cCDbSgfcrkf1n1m3A",
    authDomain: "focuser-de426.firebaseapp.com",
    projectId: "focuser-de426",
    storageBucket: "focuser-de426.appspot.com",
    messagingSenderId: "477599397294",
    appId: "1:477599397294:web:53c63381216a0f1cc8443f",
    measurementId: "G-Y4B689YFTH"
};

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(user => {
    if (user) {
        store.dispatch('completeLogin', {
            user: user,
        });
    }
});

firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase.firestore().enablePersistence()
    .then(() => {
        console.log('DB caching enabled');
    })
    .catch(function(err) {
        if (err.code === 'failed-precondition') {
            console.log('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
        } else if (err.code === 'unimplemented') {
            console.log('The current browser does not support all of the features required to enable persistence');
        }
    });

// const noSleep = new NoSleep();
//
// document.addEventListener('click', function enableNoSleep() {
//     document.removeEventListener('click', enableNoSleep, false);
//     noSleep.enable();
// }, false);

app.mount('#app')
