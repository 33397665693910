<template>
  <div id="logo-container">
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch -->
      <title>center_focus_weak</title>
      <desc>Created with Sketch.</desc>
      <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Rounded" transform="translate(-919.000000, -2727.000000)">
          <g id="Image" transform="translate(100.000000, 2626.000000)">
            <g id="-Round-/-Image-/-center_focus_weak" transform="translate(816.000000, 98.000000)">
              <g transform="translate(0.000000, 0.000000)">
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path d="M4,15 C3.45,15 3,15.45 3,16 L3,19 C3,20.1 3.9,21 5,21 L8,21 C8.55,21 9,20.55 9,20 C9,19.45 8.55,19 8,19 L6,19 C5.45,19 5,18.55 5,18 L5,16 C5,15.45 4.55,15 4,15 Z M5,6 C5,5.45 5.45,5 6,5 L8,5 C8.55,5 9,4.55 9,4 C9,3.45 8.55,3 8,3 L5,3 C3.9,3 3,3.9 3,5 L3,8 C3,8.55 3.45,9 4,9 C4.55,9 5,8.55 5,8 L5,6 Z M19,3 L16,3 C15.45,3 15,3.45 15,4 C15,4.55 15.45,5 16,5 L18,5 C18.55,5 19,5.45 19,6 L19,8 C19,8.55 19.45,9 20,9 C20.55,9 21,8.55 21,8 L21,5 C21,3.9 20.1,3 19,3 Z M19,18 C19,18.55 18.55,19 18,19 L16,19 C15.45,19 15,19.45 15,20 C15,20.55 15.45,21 16,21 L19,21 C20.1,21 21,20.1 21,19 L21,16 C21,15.45 20.55,15 20,15 C19.45,15 19,15.45 19,16 L19,18 Z M12,8 C9.79,8 8,9.79 8,12 C8,14.21 9.79,16 12,16 C14.21,16 16,14.21 16,12 C16,9.79 14.21,8 12,8 Z M12,14 C10.9,14 10,13.1 10,12 C10,10.9 10.9,10 12,10 C13.1,10 14,10.9 14,12 C14,13.1 13.1,14 12,14 Z" id="🔹-Icon-Color" fill="var(--main-text-color)"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <h1>Focuser</h1>
  </div>
</template>

<script>
export default {
  name: "TheLogo"
}
</script>

<style scoped>
svg {
  height: 33px;
  width: 33px;
}

#logo-container {
  display: flex;
  align-items: center;
}

#logo-container > img {
  height: 33px;
  width: 33px;
}

#logo-container > * {
  margin: 7px 3px 17px 3px;
}
</style>