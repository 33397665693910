<template>
  <div id="add-button" @click="showCreator=isLoggedIn;showLogin=!isLoggedIn"><p>+ Add Task</p></div>
  <base-modal v-if="showLogin" @close="showLogin = false"><auth></auth></base-modal>
  <base-modal v-if="showCreator" v-on:close="showCreator = false">
    <form v-on:submit.prevent>
      <input type="text" id="task" placeholder="Task name" v-model="task" @keyup="validateTask(); missingInfo=false">
      <input type="text" id="project" placeholder="Project name" v-model="project" @keyup="validateProject(); missingInfo=false">
      <span v-if="missingInfo" class="error">Required information is missing.</span>
      <div id="button-container">
        <base-button @click="addTask">Add Task</base-button>
      </div>
    </form>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/ui/BaseModal";
import BaseButton from "@/components/ui/BaseButton3";
import Auth from "@/views/Auth";

export default {
  task: "TaskItemCreator",

  emits: ['add-task'],

  components: {Auth, BaseButton, BaseModal},

  data() {
    return {
      showLogin: false,
      showCreator: false,
      task: '',
      project: '',
      missingInfo: false,
      maxChars: 20,
    };
  },

  computed: {
    isLoggedIn() {
      return !!this.$store.getters.user;
    },
  },

  methods: {
    addTask() {
      if (this.project === '' || this.task === '') {
        this.missingInfo = true;
        return;
      }

      this.validateProject();
      this.validateTask();

      this.$emit('add-task', {
        task: this.task,
        project: this.project,
      });

      this.showCreator = false;
      this.task = '';
      this.project = '';
    },

    validateProject() {
    },

    validateTask() {
    }
  }
}
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
}

form > * {
  margin-top: 11px;
}

#add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: darkgray;
  font-weight: bold;
  border: dashed darkgray;
  border-radius: var(--rounded-border-radius);
  max-width: 40rem;
  min-height: 85px;
}

input {
  width: 100%;
  border: none;
  background-color: var(--input-bg-color);
  color: var(--main-text-color);
  border-radius: 3px;
  padding: 5px;
}

#button-container {
  padding: 11px;
  margin-left: auto;
  margin-right: auto;
}

.error {
  color: red;
  font-weight: bold;
  font-size: .75rem;
}

</style>