<template>
  <section id="firebaseui-auth-container"></section>
</template>

<script>
import firebase from "firebase/app";
import 'firebase/auth';
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default {
  task: "Auth",

  mounted() {
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }

    let uiConfig = {
      autoUpgradeAnonymousUsers: true,
      signInSuccessUrl: '/',
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        },
      ],
      callbacks: {
        signInFailure: (error) => {
          console.log('Merge not implemented guest data will be lost');
          firebase.auth().currentUser.delete();
          firebase.auth().signInWithCredential(error.credential).then(() => {
            window.location.assign('/');
          });
        }
      }
    };

    // Only show anonymous option if the current user is not already anonymous.
    if (!this.$store.getters.user) {
      uiConfig.signInOptions.push({
        provider: firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
      })
    }

    ui.start("#firebaseui-auth-container", uiConfig);
  }
}
</script>

<style scoped>
</style>