<template>
  <div class="dropdown" @click.stop="toggle">
    <slot></slot>
    <div :class="dropdownClass" v-if="show">
      <slot name="dropdown"></slot>
    </div>
  </div>
</template>

<script>
export default {
  task: "BaseDropdown",

  props: ['direction'],

  data() {
    return {
      show: false,
    };
  },

  computed: {
    dropdownClass() {
      return {
        'dropdown-content': true,
        'dropdown-right': this.direction === 'right',
      };
    }
  },

  methods: {
    toggle() {
      this.show = !this.show;
      if (this.show) {
        document.body.addEventListener('click', () => {
          this.show = false;
        },
            {
              once: true,
            });
      }
    },
  }
}
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  right: 0;
  background-color: var(--dropdown-bg-color);
  min-width: 160px;
  width: fit-content;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-right {
  left: 0;
}

</style>