<template>
  <div id="snack-bar">
    <slot></slot>
    <nav>
      <close-button @click="$emit('close')"></close-button>
    </nav>
  </div>
</template>

<script>
import CloseButton from "@/components/ui/CloseButton";
export default {
  name: "SnackBar",
  components: {CloseButton},
  emits: ['close'],
}
</script>

<style scoped>
#snack-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #42b983;
  /*border: var(--main-text-color) solid;*/
  border-radius: 3px;
  padding: 3px;
  position: fixed;
  left: calc(50% - 158px);
  bottom: 30px;
  z-index: 1;
  width: 316px;
}

nav {
}
</style>
