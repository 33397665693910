<template>
  <div id="tabs-bar">
    <a @click="setMode('focus')" :class="{'selected': mode === 'focus'}">Focus</a>
    <a @click="setMode('short-break')" :class="{'selected': mode === 'short-break'}">Short Break</a>
    <a @click="setMode('long-break')" :class="{'selected': mode === 'long-break'}">Long Break</a>
  </div>
  <div id="timer-container">
    <h1 id="timer-display">{{ timeLeft }}</h1>
    <div id="button-container">
      <base-button size='small' @click="toggleTimerState">{{ buttonText }}</base-button>
      <base-button size='small' @click="resetTimer">Reset</base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton3";
import Worker from "worker-loader!../assets/timerWorker";

export default {
  task: "Timer",

  components: {BaseButton},

  emits: ['period-completed'],

  inject: ['focusMinutes', 'shortBreakMinutes', 'longBreakMinutes'],

  data() {
    return {
      buttonText: 'Start',
      timerRunning: false,
      resetMinutes: this.focusMinutes,
      secondsLeft: this.focusMinutes * 60,
      // timerUpdate: null,
      periodMinutes: null,
      mode: 'focus',
      focusCount: 0,
      worker: new Worker(),
    }
  },

  created() {
    this.resetTimer();
  },

  computed: {
    timeLeft() {
      let hours = Math.floor(this.secondsLeft / 3600);
      let minutes = Math.floor(this.secondsLeft % 3600 / 60);
      let seconds = this.secondsLeft % 60;

      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      return hours + ':' + minutes + ':' + seconds
    }
 },

  methods: {
    toggleTimerState() {
      if (this.timerRunning) {
        this.stopTimer();
      } else {
        this.startTimer();
      }
    },

    startTimer() {
      this.timerRunning = true;
      this.buttonText = 'Stop';

      this.worker.onmessage = (e) => {
        this.secondsLeft = e.data.seconds;

        if (this.secondsLeft === 0) {
          this.resetTimer();
          this.$emit('period-completed', {
            mode: this.mode,
            minutes: this.periodMinutes,
          });
          if (this.mode === 'focus') {
            this.focusCount++;
            if (this.focusCount % 4 === 0) {
              this.setMode('long-break');
            } else {
              this.setMode('short-break');
            }
          } else {
            this.setMode('focus');
          }
        }
      };

      this.worker.postMessage({
        cmd: 'start-timer',
        timer: {
          seconds: this.secondsLeft,
        }
      });
    },

    stopTimer() {
      this.timerRunning = false;
      this.buttonText = 'Start';
      // clearInterval(this.timerUpdate);
      this.worker.postMessage({
        cmd: 'stop-timer',
      });
    },

    resetTimer() {
      this.stopTimer();
      this.secondsLeft = this.resetMinutes * 60;
      this.periodMinutes = this.resetMinutes;
    },

    setMode(mode) {
      this.mode = mode;
      if (mode === 'focus') {
        this.resetMinutes = this.focusMinutes;
      } else if (mode === 'short-break') {
        this.resetMinutes = this.shortBreakMinutes;
      } else {
       this.resetMinutes = this.longBreakMinutes;
      }
      this.resetTimer();
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 3rem;
}

#timer-display {
  padding: 3px;
}

#button-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 355px;
}

#timer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#tabs-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

a {
  text-align: center;
  width: 133px;
}

a:hover {
  border-bottom: medium solid gray;
}

a.selected {
  border-bottom: medium solid var(--tab-border-color);
}
</style>