<template>
  <label class="switch">
    <input type="checkbox" v-model="active" @click="$emit('state-change', {active: !active})">
    <span class="slider"></span>
  </label>
</template>

<script>
export default {
  name: "ToggleSwitch",

  props: ['isActive'],

  data() {
    return {
      active: this.isActive,
    }
  },

}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--input-bg-color);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--main-bg-color);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--input-bg-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--input-bg-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>