<template>
  <div id="item">
    <div :class="{small: smallScreen}" id="description" @click="$emit('activate')">
      <span class="tag" id="project-tag">{{ project }}:</span>
      <span class="tag" id="name-tab">{{ name }}</span>
    </div>
    <base-dropdown>
      <menu-button></menu-button>
      <template v-slot:dropdown>
        <a @click="$emit('remove')">remove</a>
      </template>
    </base-dropdown>
  </div>
</template>

<script>
import BaseDropdown from "@/components/ui/BaseDropdown";
import MenuButton from "@/components/ui/MenuButton";

export default {
  task: "TaskItem",

  components: {MenuButton, BaseDropdown},

  props: ['id', 'project', 'name'],

  data() {
    return {
      smallScreen: false,
    }
  },

  created() {
    const mQList = window.matchMedia('(max-width: 500px)');
    this.smallScreen = mQList.matches;
    mQList.addListener((mq) => { this.smallScreen = mq.matches; });
  }
}
</script>

<style scoped>
#item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

#description {
  display: flex;
  width: 100%;
}

.small {
  flex-direction: column;
}

.tag {
  margin: 0.5rem;
  overflow-wrap: anywhere;
}

#project-tag {
  color: gray;
  font-weight: bold;
}

#menu-icon {
  width: 1.3rem;
  height: 1.3rem;
}

a {
  color: var(--dropdown-text-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

a:hover {
  background-color: var(--dropdown-hl-color);
}

</style>