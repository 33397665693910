<template>
  <a href="#" class="btn-push">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "BaseButton3"
}
</script>

<style scoped>
.btn-push {
  position: relative;
  top: 0px;
  text-decoration: none;
  font-size: 14px;
  background: var(--push-btn-bg-color);
  padding: 0.75em 1.5em;
  margin: 0 10px;
  border: 1px solid var(--push-btn-border-color);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 0.25em;
  -webkit-box-shadow: -0.5px 4px 0px var(--push-btn-shadow-color);
  -moz-box-shadow: -0.5px 4px 0px var(--push-btn-shadow-color);
  -ms-box-shadow: -0.5px 4px 0px var(--push-btn-shadow-color);
  -o-box-shadow: -0.5px 4px 0px var(--push-btn-shadow-color);
  box-shadow: -0.5px 4px 0px var(--push-btn-shadow-color);
  color: var(--push-btn-text-color);
  -webkit-transition: All 250ms ease;
  -moz-transition: All 250ms ease;
  -o-transition: All 250ms ease;
  -ms-transition: All 250ms ease;
  transition: All 250ms ease;
}
/*==========  Active State  ==========*/
.btn-push:active {
  position: relative;
  top: 5px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: All 250ms ease;
  -moz-transition: All 250ms ease;
  -o-transition: All 250ms ease;
  -ms-transition: All 250ms ease;
  transition: All 250ms ease;
}

.btn-push:hover {
  border: 1px solid var(--push-btn-border-color);
}
</style>