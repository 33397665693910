<template>
  <div id="settings-container">
    <div class="setting">
      Dark Mode:
      <toggle-switch
          :is-active="darkModeActive"
          v-on:state-change="switchTheme"
      ></toggle-switch>
    </div>
    <div class="setting">
      Play Chime:
      <toggle-switch
          :is-active="chimeActive"
          v-on:state-change="switchChime"
      ></toggle-switch>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from "@/components/ui/ToggleSwitch";

export default {
  name: "UserSettings",

  components: {ToggleSwitch},

  computed: {
    darkModeActive() {
      return this.$store.getters.darkMode;
    },

    chimeActive() {
      return this.$store.getters.playChime;
    }
  },

  methods: {
    switchTheme(payload) {
      this.$store.dispatch('updateTheme', {isDark: payload.active});
    },

    switchChime(payload) {
      this.$store.dispatch('updateChime', {playChime: payload.active});
    }
  }

}
</script>

<style scoped>
.setting {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}
</style>