<template>
    <transition name="modal">
      <div class="modal-mask" @click="$emit('close')" @scroll.stop>
        <div class="modal-wrapper">
          <div class="modal-container" v-on:click.stop>
            <div id="close-button-container">
              <close-button @click="$emit('close')"></close-button>
            </div>
            <div class="modal-body">
              <slot>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
</template>

<script>
import CloseButton from "@/components/ui/CloseButton";
export default {
  components: {CloseButton},
  task: "BaseModal",

  emits: ['close'],
}
</script>

<style scoped>
#close-button-container {
  display: flex;
  justify-content: flex-end;
}

a {
  border: black solid;
  padding: .3rem;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  box-sizing: border-box;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 95vw;
  max-width: 500px;
  margin: 0px auto;
  padding: 10px 10px;
  background-color: var(--main-bg-color);
  border-radius: var(--rounded-border-radius);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>