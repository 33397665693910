<template>
  <svg height="18px" version="1.1" viewBox="0 0 18 18" width="18px" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink"><title/><desc/><defs/><g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g fill="#000000" id="Core" transform="translate(-507.000000, -381.000000)"><g id="settings-applications" transform="translate(507.000000, 381.000000)"><path d="M9,7 C7.9,7 7,7.9 7,9 C7,10.1 7.9,11 9,11 C10.1,11 11,10.1 11,9 C11,7.9 10.1,7 9,7 L9,7 Z M16,0 L2,0 C0.9,0 0,0.9 0,2 L0,16 C0,17.1 0.9,18 2,18 L16,18 C17.1,18 18,17.1 18,16 L18,2 C18,0.9 17.1,0 16,0 L16,0 Z M14.2,9 L14.2,9.7 L15.7,10.9 C15.8,11 15.9,11.2 15.8,11.3 L14.4,13.7 C14.3,13.9 14.1,13.9 14,13.9 L12.3,13.2 C11.9,13.5 11.5,13.7 11.1,13.9 L10.8,15.8 C10.8,16 10.6,16.1 10.5,16.1 L7.7,16.1 C7.5,16.1 7.4,16 7.4,15.8 L7,13.9 C6.6,13.7 6.2,13.5 5.8,13.2 L4.1,13.9 C3.9,14 3.8,13.9 3.7,13.7 L2.3,11.3 C2.2,11.1 2.3,11 2.4,10.9 L3.9,9.7 L3.9,9 L3.9,8.3 L2.4,7.1 C2.3,7 2.2,6.8 2.3,6.7 L3.7,4.3 C3.8,4.1 4,4.1 4.1,4.1 L5.8,4.8 C6.2,4.6 6.6,4.3 7,4.1 L7.3,2.2 C7.3,2 7.5,1.9 7.6,1.9 L10.4,1.9 C10.6,1.9 10.7,2 10.7,2.2 L11,4.1 C11.4,4.3 11.8,4.5 12.2,4.8 L13.9,4.1 C14.1,4 14.2,4.1 14.3,4.3 L15.7,6.7 C15.8,6.9 15.7,7 15.6,7.1 L14.1,8.3 C14.2,8.5 14.2,8.8 14.2,9 L14.2,9 Z" id="Shape"/></g></g></g></svg>

</template>

<script>
export default {
  name: "SettingsButton"
}
</script>

<style scoped>
svg {
  border: var(--main-text-color) solid;
  border-radius: 3px;
  padding: 3px;
  width: 33px;
  height: 33px;
  fill: var(--main-text-color);
}

svg > * {
  fill: var(--main-text-color);
}

#Core {
  fill: var(--main-text-color);
}
</style>