<template>
  <div id="chartContainer">
    <canvas id="theChart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'StackedBarChart',

  props: ['data', 'period'],

  data() {
    return {
      chart: null,
      chartData: {
        labels: [],
        datasets: [],
      },
    }
  },

  watch: {
    data: function () {
      this.updateChart();
    }
  },

  methods: {
    buildChartData() {
      let makeLabel = function (date) {
        return date.toDateString();
      }

      if (this.period === "Week") {
        makeLabel = function (date) {
          return date.toDateString().split(' ')[0]
        }
      } else if (this.period === "Month") {
        makeLabel = function (date) {
          const dateStrings = date.toDateString().split(' ');
          return dateStrings[0] + ' ' + dateStrings[2];
        }
      } else if (this.period === "Year") {
        makeLabel = function (date) {
          return date.toDateString().split(' ')[1];
        }
      }

      const backgroundColors = [
        'rgb(16,205,107, 0.2)',
        'rgb(198,1,241, 0.2)',
        'rgb(26,160,255, 0.2)',
        'rgb(255,133,0, 0.2)',
        'rgb(187,206,0, 0.2)',
        'rgb(0,42,246, 0.2)',

        'rgb(241,118,154, 0.2)',
        'rgba(34, 252, 235, 0.2)',
        'rgba(155, 106, 86, 0.2)',
        'rgb(45,149,0, 0.2)',
        'rgba(113, 102, 255, 0.2)',
        'rgb(151,67,1, 0.2)',

        'rgb(201,130,245, 0.2)',
        'rgb(97,119,187, 0.2)',
        'rgb(163,127,114, 0.2)',
        'rgb(192,75,108, 0.2)',
        'rgb(64,48,73, 0.2)',
        'rgb(188,255,64, 0.2)'
      ];

      const borderColors = [
        'rgb(16,205,107, 1)',
        'rgb(198,1,241, 1)',
        'rgb(26,160,255, 1)',
        'rgb(255,133,0, 1)',
        'rgb(187,206,0, 1)',
        'rgb(0,42,246, 1)',

        'rgb(241,118,154, 1)',
        'rgba(34, 252, 235, 1)',
        'rgba(155, 106, 86, 1)',
        'rgb(45,149,0, 1)',
        'rgba(113, 102, 255, 1)',
        'rgb(151,67,1, 1)',

        'rgb(201,130,245, 1)',
        'rgb(97,119,187, 1)',
        'rgb(163,127,114, 1)',
        'rgb(192,75,108, 1)',
        'rgb(64,48,73, 1)',
        'rgb(188,255,64, 1)'
      ]

      const labels = [];
      const datasets = [];

      // Populate project datasets with zero values.
      let colorIdx = 0;
      for (const name of this.data.projects) {
        const data = new Array(this.data.periods.length);
        for (let i = 0; i < data.length; i++) {
          data[i] = 0;
        }

        datasets.push({
          label: name,
          data: data,
          backgroundColor: backgroundColors[colorIdx],
          borderColor: borderColors[colorIdx],
          borderWidth: 1,
        });

        colorIdx++;
        if (colorIdx >= backgroundColors.length) {
          colorIdx = 0;
        }
      }

      this.data.periods.forEach((period, periodIdx) => {
        labels.push(makeLabel(period.startingDate));

        for (const name in period.projects) {
          const project = period.projects[name];
          for (let i = 0; i < datasets.length; i++) {
            const ds = datasets[i];
            if (ds.label === name) {
              ds.data[periodIdx] += project.totalMinutes;
              break;
            }
          }
        }
      });

      this.chartData = {
        labels: labels,
        datasets: datasets,
      };
    },

    updateChart() {
      if (this.createChart()) {
        return;
      }

      this.buildChartData();
      this.chart.data = this.chartData;
      this.chart.update();
    },

    createChart() {
      if (this.chart != null) {
        return false;
      }

      this.buildChartData();

      const chartElement = document.querySelector('canvas');
      const options = {
        type: 'bar',
        data: this.chartData,
        options: {
          title: {
            display: false,
            text: 'Projects'
          },
          tooltips: {
            mode: 'index',
            intersect: false
          },
          responsive: true,
          // maintainAspectRatio: false,
          aspectRatio: 1,
          scales: {
            xAxes: [{
              stacked: true,
            }],
            yAxes: [{
              stacked: true
            }]
          }
        }
      };

      this.chart = new Chart(chartElement, options);

      this.chart.update();

      return true;
    }
  }
  ,
}
</script>

<style scoped>
#chartContainer {
  width: 100%;
}

#theChart {
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>