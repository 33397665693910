<template>
  <div id="list-card" @click="$emit('activate')"  :class="{active: active}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  task: "BaseListCard",

  props: ['active'],
}
</script>

<style scoped>
#list-card {
  display: flex;
  border-radius: var(--rounded-border-radius);
  max-width: 40rem;
  min-height: 85px;
  border: solid var(--list-card-border-color) 1px;
  box-shadow: 0 1px 1px var(--list-card-shadow-color);
}

.active {
  background-color: var(--active-selection-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

</style>