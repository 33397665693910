import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';

import {createStore} from 'vuex'

export default createStore({
    state: {
        user: null,
        darkMode: false,
        playChime: true,
    },

    getters: {
        user(state) {
            return state.user;
        },

        darkMode(state) {
            return state.darkMode;
        },

        playChime(state) {
            return state.playChime;
        }
    },

    mutations: {
        setUser(state, user) {
            state.user = user;
        },

        setDarkMode(state, isDark) {
            state.darkMode = isDark;
        },

        setPlayChime(state, play) {
            state.playChime = play;
        }
    },

    actions: {
        updateChime(context, payload) {
            const user = context.getters.user;
            if (payload.playChime !== context.getters.playChime && user) {
                const db = firebase.firestore();
                db.collection('users')
                    .doc(user.id)
                    .set({
                        playChime: payload.playChime,
                    }, {
                        merge: true,
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

            context.commit('setPlayChime', payload.playChime);
        },

        updateTheme(context, payload) {
            const user = context.getters.user;
            if (payload.isDark !== context.getters.darkMode && user) {
                const db = firebase.firestore();
                db.collection('users')
                    .doc(user.id)
                    .set({
                        darkMode: payload.isDark,
                    }, {
                        merge: true,
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

            context.commit('setDarkMode', payload.isDark);

            if (payload.isDark) {
                document.querySelector('body').classList.remove('light');
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
                document.querySelector('body').classList.add('light');
            }
        },

        logout(context) {
            firebase.auth().signOut().then(function () {
                localStorage.removeItem('user');
                context.commit('setUser', null);
            }).catch((error) => {
                console.log(error);
            });
        },

        completeLogin(context, payload) {
            if (!payload.user.uid) {
                console.log('Could not login user without uid');
                return
            }

            let user = {
                id: payload.user.uid,
                email: payload.user.email,
                displayName: payload.user.displayName,
                photoURL: payload.user.photoURL,
                isAnonymous: payload.user.isAnonymous,
            }

            try {
                localStorage.setItem('user', JSON.stringify(user));
            } catch (error) {
                console.log(error);
            }

            context.commit('setUser', user);
        },

        loadGlobalState(context) {
            const user = JSON.parse(localStorage.getItem('user'));

            if (!user || !user.id) {
                console.log('Could not load state without user uid');
                return;
            }

            context.commit('setUser', user);

            const db = firebase.firestore();
            db.collection('users')
                .doc(user.id)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        const userData = doc.data();
                        if ('darkMode' in userData) {
                            context.dispatch('updateTheme', {isDark: userData.darkMode});
                        }

                        if ('playChime' in userData) {
                            context.commit('setPlayChime', userData.playChime);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },

    modules: {}
})
