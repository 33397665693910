<template>
<!--  <svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><title/><g data-name="2" id="_2"><path d="M28,25V10a1,1,0,0,0-1-1H23a1,1,0,0,0-1,1V25H20V6a1,1,0,0,0-1-1H15a1,1,0,0,0-1,1V25H12V15a1,1,0,0,0-1-1H7a1,1,0,0,0-1,1V25H4V5H2V26a1,1,0,0,0,1,1H30V25ZM24,11h2V25H24ZM16,7h2V25H16ZM8,16h2v9H8Z" id="bar_chart_graph_analysis"/></g></svg>-->
  <svg enable-background="new 0 0 24 24" height="24px" id="Layer_1" version="1.1" viewBox="0 0 24 24" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M2.9,17.4H1c-0.3,0-0.5,0.2-0.5,0.5v4c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5v-4C3.4,17.6,3.2,17.4,2.9,17.4z"/><path d="M7,11.6H5c-0.3,0-0.5,0.2-0.5,0.5v9.8c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5v-9.8C7.4,11.8,7.2,11.6,7,11.6z"/><path d="M11,14.5H9c-0.3,0-0.5,0.2-0.5,0.5v6.9c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5V15C11.5,14.7,11.2,14.5,11,14.5z"/><path d="M15,8.7h-2c-0.3,0-0.5,0.2-0.5,0.5v12.7c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5V9.2C15.5,8.9,15.3,8.7,15,8.7z"/><path d="M19,11.6h-2c-0.3,0-0.5,0.2-0.5,0.5v9.8c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5v-9.8C19.5,11.8,19.3,11.6,19,11.6z   "/><path d="M23,5.8h-2c-0.3,0-0.5,0.2-0.5,0.5v15.6c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5V6.3C23.5,6,23.3,5.8,23,5.8z"/><path d="M23,1.7H1c-0.3,0-0.5,0.2-0.5,0.5v2c0,0.3,0.2,0.5,0.5,0.5h22c0.3,0,0.5-0.2,0.5-0.5v-2C23.5,1.9,23.3,1.7,23,1.7z"/></g></svg>
</template>

<script>
export default {
  name: "DataButton"
}
</script>

<style scoped>
svg {
  border: var(--main-text-color) solid;
  border-radius: 3px;
  padding: 3px;
  fill: var(--main-text-color);
  width: 33px;
  height: 33px;
}
</style>