<template>
  <svg style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="close"><g><circle cx="256" cy="256" r="253.44"/><path
      class="inner"
      d="M350.019,144.066l17.521,17.522c6.047,6.047,6.047,15.852,0,21.9L183.607,367.419    c-6.047,6.048-15.852,6.047-21.9,0l-17.521-17.522c-6.047-6.047-6.047-15.852,0-21.9l183.932-183.933    C334.166,138.018,343.971,138.018,350.019,144.066z"/><path
      class="inner"
      d="M367.54,349.899l-17.522,17.522c-6.047,6.047-15.852,6.047-21.9,0L144.186,183.488    c-6.047-6.047-6.047-15.852,0-21.9l17.522-17.522c6.047-6.047,15.852-6.047,21.9,0L367.54,327.999    C373.588,334.047,373.588,343.852,367.54,349.899z"/></g></g>
    <g id="Layer_1"/></svg>
</template>

<script>
export default {
  name: "CloseButton"
}
</script>

<style scoped>
svg {
  height: 33px;
  width: 33px;
  fill: var(--main-bg-color);
}

.inner {
  fill: var(--main-text-color);
}

</style>