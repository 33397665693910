export default {
    data() {
        return {
            updating: false,
            registration: null,
            updateExist: false,
        }
    },

    created() {
        document.addEventListener('updateAvailable', this.updateAvailable, { once: true });

        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.updating) {
                return;
            }
            this.updating = true;
            window.location.reload();
        });
    },

    methods: {
        updateAvailable(event) {
            this.registration = event.detail;
            this.updateExist = true;
        },

        updateApplication() {
            this.updateExist = false;
            if (!this.registration || !this.registration.waiting) {
                return;
            }
            this.registration.waiting.postMessage({type: 'SKIP_WAITING'});
        }
    },
}