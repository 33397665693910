<template>
  <div id="task-data">
    <input type="date" v-on:input="setStartingDate" v-model="startingDate">
    <tabs :value="period" :tabs="periods" v-on:input="updatePeriod"></tabs>
    <stacked-bar-chart :data="data" :period="period"></stacked-bar-chart>
  </div>
</template>

<script>
import Tabs from "@/components/ui/Tabs";
import firebase from "firebase/app";
import 'firebase/firestore';
import StackedBarChart from "@/components/StackedBarChart";

const currentDate = function () {
  const date = new Date();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
  return date.toISOString().slice(0,10);
}

export default {
  name: "TaskData",
  components: {StackedBarChart, Tabs},

  data() {
    return {
      startingDate: currentDate(),
      period: "Week",
      periods: ["Day", "Week", "Month", "Year"],
      data: {
        periods: [],
        projects: new Set(),
      },
    };
  },

  activated() {
    this.getData();
  },

  methods: {
    setStartingDate(event) {
      this.startingDate = event.target.value;
      this.getData();
    },

    updatePeriod(period) {
      this.period = period;

      if (this.startingDate === null) {
        return;
      }

      this.getData();
    },

    getData() {
      const user = this.$store.getters.user;
      if (!user) {
        return;
      }

      class DataPeriod {
        constructor(startingDate) {
          this.startingDate = startingDate;
          this.totalMinutes = 0;
          this.projects = {};
        }

        addTask(projectName, taskName, minutes) {
          this.totalMinutes += minutes;
          if (this.projects[projectName]) {
            this.projects[projectName].addTask(taskName, minutes);
          } else {
            const project = new Project()
            project.addTask(taskName, minutes);
            this.projects[projectName] = project;
          }
        }
      }

      class Project {
        constructor() {
          this.totalMinutes = 0;
          this.tasks = {};
        }

        addTask(taskName, minutes) {
          this.totalMinutes += minutes;
          if (this.tasks[taskName]) {
            this.tasks[taskName].addMinutes(minutes);
          } else {
            const task = new Task();
            task.addMinutes(minutes);
            this.tasks[taskName] = task;
          }
        }
      }

      class Task {
        constructor() {
          this.totalMinutes = 0;
        }

        addMinutes(minutes) {
          this.totalMinutes += minutes;
        }
      }

      const newPeriods = [];
      const newProjects = new Set();

      const startingDate = new Date(this.startingDate);
      startingDate.setUTCMinutes(startingDate.getUTCMinutes() + startingDate.getTimezoneOffset());
      const endingDate = new Date(startingDate.getTime());
      if (this.period === "Day") {

        endingDate.setUTCDate(startingDate.getUTCDate() + 1);

        newPeriods.push(new DataPeriod(startingDate));

      } else if (this.period === "Week") {

        startingDate.setUTCDate(startingDate.getUTCDate() - startingDate.getUTCDay());
        endingDate.setUTCDate(startingDate.getUTCDate() + 7);

        let start = new Date(startingDate.getTime());
        for (let i = 0; i < 7; i++) {
          newPeriods.push(new DataPeriod(new Date(start.getTime())));
          start.setDate(start.getDate() + 1);
        }

      } else if (this.period === "Month") {

        startingDate.setUTCDate(1);
        endingDate.setUTCDate(1);
        endingDate.setUTCMonth(startingDate.getUTCMonth() + 1);

        const start = new Date(startingDate.getTime());
        for (let i = 0; i < 5; i++) {
          newPeriods.push(new DataPeriod(new Date(start.getTime())));
          start.setDate(start.getDate() + 7);
        }

      } else if (this.period === "Year") {

        startingDate.setUTCFullYear(startingDate.getUTCFullYear(), 0, 1);
        endingDate.setUTCFullYear(startingDate.getUTCFullYear() + 1, 0, 1);

        const start = new Date(startingDate.getTime());
        for (let i = 0; i < 12; i++) {
          newPeriods.push(new DataPeriod(new Date(start.getTime())));
          start.setMonth(start.getMonth() + 1);
        }
      }

      const db = firebase.firestore();
      db.collection('users')
          .doc(user.id)
          .collection('completedPeriods')
          .where('timeStamp', '>=', startingDate.getTime())
          .where('timeStamp', '<', endingDate.getTime())
          .get()
          .then((query) => {
            query.forEach((doc) => {
              const data = doc.data();
              const date = new Date(data.timeStamp);

              newProjects.add(data.project);

              if (this.period === "Day") {
                newPeriods[0].addTask(data.project, data.task, data.minutes);
              } else if (this.period === "Week") {
                newPeriods[date.getDay()].addTask(data.project, data.task, data.minutes);
              } else if (this.period === "Month") {
                newPeriods[Math.floor((date.getDate() - 1) / 7) ].addTask(data.project, data.task, data.minutes);
              } else {
                newPeriods[date.getMonth()].addTask(data.project, data.task, data.minutes);
              }
            });

            this.data = {
              periods: newPeriods,
              projects: newProjects,
            };
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }
}
</script>

<style scoped>
#task-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#task-data > * {
  margin: 11px;
}
</style>