<template>
  <h3>Tasks</h3>
  <ul>
    <li v-for="task in tasks" :key="task.id">
      <base-list-card2 :active="task.active" v-on:activate="activateTask(task.id)">
        <task-item
            :name="task.name"
            :project="task.project"
            :active="task.active"
            v-on:remove="removeTask(task.id)"
        ></task-item>
      </base-list-card2>
    </li>
  </ul>
  <task-item-creator v-on:addTask="addTask"></task-item-creator>
</template>

<script>
import firebase from "firebase/app";
import 'firebase/firestore';
import TaskItem from "@/components/TaskItem";
import {v4 as uuidv4} from 'uuid';
import BaseListCard2 from "@/components/ui/BaseListCard2";
import TaskItemCreator from "@/components/TaskItemCreator";

export default {
  task: "TaskList",

  emits: ['focus-changed'],

  components: {BaseListCard2, TaskItem, TaskItemCreator},

  data() {
    return {
      tasks: []
    };
  },

  created() {
    this.loadList();

    this.$store.watch((state) => state.user, (newValue) => {
      if (newValue === null) {
        this.tasks = [];
        this.$emit('focus-changed', {
          project: '',
          task: '',
        });
      }
    });
  },

  methods: {
    loadList() {
      this.tasks = [];
      const user = this.$store.getters.user;
      if (!user) {
        return;
      }

      const db = firebase.firestore();
      db.collection('users')
          .doc(user.id)
          .collection('tasks')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const task = doc.data();
              this.tasks.push(task);

              if (task.active) {
                this.$emit('focus-changed', {
                  task: task.name,
                  project: task.project,
                });
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
    },

    removeTask(id) {
      const user = this.$store.getters.user;
      if (!user) {
        return;
      }

      const taskIdx = this.tasks.findIndex((task) => task.id === id);
      if (taskIdx < 0) {
        console.log(`task with id ${id} not found`);
        return;
      }

      const task = this.tasks[taskIdx];

      const db = firebase.firestore();
      db.collection('users')
          .doc(user.id)
          .collection('tasks')
          .doc(id)
          .delete()
          .then(() => {
            // console.log('Task removed from DB');
          }).catch(function (error) {
        // console.log('Could not remove task from DB');
        console.log(error);
      });
      this.removeTaskFromLocalList(task); // We do this unconditionally. If it fails there is no harm.
    },

    activateTask(id) {
      const activateIdx = this.tasks.findIndex((task) => task.id === id);
      if (activateIdx === -1) {
        return;
      }

      const deactivateIdx = this.tasks.findIndex((task) => task.active);
      if (activateIdx === deactivateIdx) {
        return;
      }

      this.tasks[activateIdx].active = true;
      if (deactivateIdx !== -1) {
        this.tasks[deactivateIdx].active = false;
      }
      this.$emit('focus-changed', {
        task: this.tasks[activateIdx].name,
        project: this.tasks[activateIdx].project,
      });

      const user = this.$store.getters.user;
      if (!user) {
        return;
      }

      const db = firebase.firestore();
      const batch = db.batch();
      const activateTaskRef = db.collection('users')
          .doc(user.id)
          .collection('tasks')
          .doc(this.tasks[activateIdx].id);

      batch.update(activateTaskRef, {active: true});

      if (deactivateIdx !== -1) {
        const deactivateTaskRef = db.collection('users')
            .doc(user.id)
            .collection('tasks')
            .doc(this.tasks[deactivateIdx].id);
        batch.update(deactivateTaskRef, {active: false});
      }

      batch.commit().catch((error) => {
        console.log(error)
        this.tasks[activateIdx].active = false;
        if (deactivateIdx !== -1) {
          this.tasks[deactivateIdx].active = true;
          this.$emit('focus-changed', {
            task: this.tasks[deactivateIdx].name,
            project: this.tasks[deactivateIdx].project,
          });
        } else {
          this.$emit('focus-changed', {
            task: '',
            project: '',
          });
        }
      });
    },

    addTask(payload) {
      if (this.tasks.findIndex((task) => task.project === payload.project && task.name === payload.task) !== -1) {
        return;
      }

      const task = {
        id: uuidv4(),
        name: payload.task,
        project: payload.project,
        active: false,
      }

      const user = this.$store.getters.user;
      if (!user) {
        return;
      }

      const db = firebase.firestore();
      db.collection('users')
          .doc(user.id)
          .collection('tasks')
          .doc(task.id)
          .set(task)
          .then(() => {
            // console.log('Task added to DB');
          })
          .catch((error) => {
            // console.log('Could not add task to DB');
            console.log(error);
          })
      this.addTaskToLocalList(task); // We do this unconditionally. If there is an error we'll remove it locally.
    },

    addTaskToLocalList(task) {
      task.active = false;
      this.tasks.push(task);
      this.activateTask(task.id);
    },

    removeTaskFromLocalList(targetTask) {
      this.tasks = this.tasks.filter((task) => targetTask.id !== task.id);
      if (!targetTask.active) {
        // If the removed task wasn't active we have nothing else to do.
        return;
      }
      if (this.tasks.length > 0) {
        this.activateTask(this.tasks[0].id);
      } else {
        this.$emit('focus-changed', {
          task: '',
          project: '',
        });
      }
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
}

ul > li {
  margin: 1rem auto;
}

</style>