<template>
  <div id="tabs-bar">
    <a v-for="tab in tabs"
       :key="tab"
       @click="updateSelected(tab)"
       :class="{'selected': value === tab}"
    >{{ tab }}</a>
  </div>
</template>

<script>
export default {
  name: "Tabs",

  props: ["value", "tabs"],

  methods: {
    updateSelected(tab) {
      this.$emit('input', tab);
    }
  }
}
</script>

<style scoped>
#tabs-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

a {
  flex: 1 1 0;
  text-align: center;
  word-wrap: normal;
  /*margin: 7px;*/
  padding: 3px;
  /*background-color: #42b983;*/
  /*width: 100px;*/
  border-bottom: var(--main-bg-color) solid;
}

a:hover {
  border-bottom: medium solid gray;
}

a.selected {
  border-bottom: medium solid var(--tab-border-color);
}
</style>