<template>
  <header :class="{'small-header': smallScreen}">
    <the-logo></the-logo>
    <nav>
      <data-button @click="showUserData=isLoggedIn;showLogin=!isLoggedIn">Data</data-button>
      <settings-button v-on:click="showSettings=true">Settings</settings-button>
      <base-dropdown direction="left" v-if="isLoggedIn">
      <the-profile-icon v-if="isLoggedIn"></the-profile-icon>
        <template v-slot:dropdown>
          <a v-if="canLogout" @click="logout">Logout</a>
          <a v-else @click="showLogin=true">Login</a>
        </template>
      </base-dropdown>
      <profile-button v-else size="tiny" @click="showLogin = true">Login</profile-button>
    </nav>
  </header>
  <keep-alive>
    <base-modal v-if="showUserData" v-on:close="showUserData=false">
      <task-data></task-data>
    </base-modal>
  </keep-alive>
  <base-modal v-if="showSettings" v-on:close="showSettings=false">
    <user-settings></user-settings>
  </base-modal>
  <base-modal v-if="showLogin" @close="showLogin = false"><auth></auth></base-modal>
</template>

<script>
import TheProfileIcon from "@/components/layout/TheProfileIcon";
import BaseDropdown from "@/components/ui/BaseDropdown";
import Auth from "@/views/Auth";
import BaseModal from "@/components/ui/BaseModal";
import UserSettings from "@/views/UserSettings";
import DataButton from "@/components/ui/DataButton";
import SettingsButton from "@/components/ui/SettingsButton";
import ProfileButton from "@/components/ui/ProfileButton";
import TheLogo from "@/components/layout/TheLogo";
import TaskData from "@/views/TaskData";

export default {
  task: "TheHeader",

  components: {
    TaskData,
    TheLogo,
    ProfileButton,
    SettingsButton,
    DataButton, UserSettings, BaseModal, Auth, BaseDropdown, TheProfileIcon},

  data() {
    return {
      showLogin: false,
      showUserData: false,
      showSettings: false,
      smallScreen: false,
    };
  },

  computed: {
    canLogout() {
      return !!this.$store.getters.user && !this.$store.getters.user.isAnonymous;
    },

    isLoggedIn() {
      return !!this.$store.getters.user;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('logout');
    },

    goDark() {
      this.$store.dispatch('updateTheme', { isDark: true })
    }
  }
}
</script>

<style scoped>
header {
  border-bottom: var(--main-text-color) solid;
}

nav {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

nav > * {
  margin: 5px;
}

a {
  color: var(--dropdown-text-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

a:hover {
  background-color: var(--dropdown-hl-color);
}

</style>